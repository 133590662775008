<template>
  <div class="container">
    <div class="operate-line">
      <div></div>
      <a-icon class="add-address" type="plus" @click="handleShowModal(null)" />
    </div>
    <!-- 收货地址栏 -->
    <div class="address-block" v-for="(item, index) of list" :key="index">
      <div @click="handleSelectAddress(item)">
        <div class='region-line'>
          <div class="default-address" v-if="item.isDefault">默认</div>
          <div class="region">{{item.regionStr}}</div>
        </div>
        <div class='address'>{{item.address}}</div>
        <div class='contact-info'>
          <span>{{item.username}}</span>
          <span class='phone-number'>{{item.phoneNumber}}</span>
        </div>
      </div>
      <div class="address-operator">
        <a-icon type="edit" @click="handleShowModal(item)" />
        <a-popconfirm title="确定删除" @confirm="() => handleDel(item)">
          <a-icon type="delete" />
        </a-popconfirm>
      </div>
    </div>

    <!-- 加载提示 -->
    <loading :is-loading="isLoading" :is-all-loaded="isAllLoaded"></loading>

    <!-- 添加和编辑框 -->
    <address-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      :isEdit="isEdit"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />
  </div>
</template>

<script>
import Loading from '@/components/loading/loading'
import page from '@/mixins/page'
import { copy, deepCopy } from '@/utils/object'
import AddressModal from './AddressModal'

export default {
  name: 'AddressList',

  components: { Loading, AddressModal },

  mixins: [page],

  data () {
    return {
      modalVisible: false,
      modalLoading: false,
      addModalTitle: '添加收货地址',
      editModalTitle: '编辑收货地址信息',
      isEdit: false,

      // 添加或编辑表单
      addForm: {
        username: '',
        phoneNumber: '',
        region: [],
        address: '',
        isDefault: false
      },
      editForm: {
        id: -1,
        username: '',
        phoneNumber: '',
        region: [],
        address: '',
        isDefault: false
      },
      form: {}
    }
  },

  computed: {
    modalTitle () {
      return this.isEdit ? this.editModalTitle : this.addModalTitle
    },
    // 获取列表的参数
    args () {
      return {
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  beforeMount () {
    // 监听滚动到底的事件
    window.addEventListener('scroll', this.onReachBottom(this.getAddressList))
    this.getAddressList()
  },

  beforeDestroy () {
    removeEventListener('scroll', this.onReachBottom(this.getAddressList))
  },

  methods: {
    // 获取数据
    getAddressList (isLazy = false) {
      this.api.getAddressListByUser(this.args).then(res => {
        const list = res.data.getAddressListByUser.data
        for (const i in list) {
          if (['北京市', '上海市', '天津市', '重庆市'].includes(list[i].region[0])) {
            list[i].regionStr = list[i].region[1] + list[i].region[2]
            continue
          }
          list[i].regionStr = list[i].region.join('')
        }
        if (isLazy) {
          this.list = list
        } else {
          this.list = this.list.concat(list)
        }
        this.total = res.data.getAddressListByUser.total
        this.isLoading = false
        this.isAllLoaded = this.list.length >= this.total
      }).catch(() => {
        this.isLoading = false
      })
    },

    // 打开弹框
    handleShowModal (record = undefined) {
      if (record) {
        this.form = deepCopy(this.editForm)
        copy(this.form, record)
        this.isEdit = true
      } else {
        this.form = deepCopy(this.addForm)
        this.isEdit = false
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },
    // 添加或编辑
    handleAddOrEdit () {
      this.$refs.modal.$refs.form.validate(valid => {
        if (!valid) return
        if (this.form.region.length !== 3) return this.$message.error('请选择所在区域')
        if (this.isEdit) {
          this.handleEdit(deepCopy(this.form))
        } else {
          delete this.form.id
          this.handleAdd(deepCopy(this.form))
        }
      })
    },

    // 添加数据
    handleAdd (form) {
      this.api.addAddressByUser(form).then(res => {
        if (res.data.addAddressByUser.id) {
          this.$message.success('添加成功')
          this.handleResetPage()
          this.getAddressList(true)
          this.handleCloseModal()
        }
      })
    },

    // 编辑数据
    handleEdit (form) {
      this.api.editAddressByUser(form).then(res => {
        try {
          if (res.data.editAddressByUser.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.handleResetPage()
            this.getAddressList(true)
            this.handleCloseModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 删除数据
    handleDel (record) {
      this.api.delAddressByUser(record.id).then(res => {
        try {
          const c = res.data.delAddressByUser.rowsAffected
          if (c !== undefined && c >= 0) {
            this.$message.success('删除成功')
            this.handleResetPage()
            this.getAddressList(true)
            return
          }
          this.$message.error('删除失败')
        } catch (e) {
          this.$message.error('删除失败')
        }
      })
    },

    // 选择该收货地址
    handleSelectAddress (record) {
      this.$store.commit('setAddress', record)
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.container {
  min-width: 600px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  margin-top: 20px;
}

.operate-line {
  display: flex;
  justify-content: space-between;
}
.add-address {
  font-size: 23px;
}
.add-address:hover {
  color: var(--blue-color);
  cursor: pointer;
}

.address-block {
  display: grid;
  grid-template-columns: 1fr 100px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 25px;
  cursor: pointer;
}
.address-block:hover {
  box-shadow: 0 0 5px 1px rgba(111, 111, 111, 0.1);
}
.address-block > div:first-child {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
}
.region-line {
  display: flex;
  align-items: center;
}
.default-address {
  font-size: 12px;
  color: #fff;
  background-color: var(--red-color);
  margin-right: 10px;
  padding: 0 6px;
  border-radius: 5px;
}
.region {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.address {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone-number {
  margin-left: 15px;
}
.address-operator {
  display: flex;
  align-items: center;
  justify-content: center;
}
.address-operator > i:first-child {
  margin-right: 10px;
}
.address-operator {
  font-size: 20px;
}
.address-operator > i:hover {
  color: var(--blue-color);
  cursor: pointer;
}
</style>
