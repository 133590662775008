<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="800"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="username" label="收件人姓名">
          <a-input placeholder="收件人姓名" v-model="form.username" />
        </a-form-model-item>

        <a-form-model-item prop="phoneNumber" label="收件人手机号">
          <a-input placeholder="收件人手机号" v-model="form.phoneNumber" />
        </a-form-model-item>

        <a-form-model-item prop="region" label="所在区域">
          <a-cascader v-if="visible" :options="areaList" :default-value="form.region" placeholder="选择所在区域" @change="handleChangeRegion" />
        </a-form-model-item>

        <a-form-model-item prop="address" label="具体地址">
          <a-input placeholder="具体地址" v-model="form.address" />
        </a-form-model-item>

        <a-form-model-item prop="isDefault" label="是否默认地址">
          <a-switch @change="form.isDefault = !form.isDefault" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import areaList from '@/utils/area'
export default {
  name: 'AddressModal',

  data () {
    return {
      // 区域信息
      areaList: areaList,

      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        username: [
          { required: true, message: '请输入收件人姓名', trigger: ['blur', 'change'] },
          { min: 1, max: 20, message: '收件人长度为1-20', trigger: ['blur', 'change'] }
        ],
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
          { min: 11, max: 11, message: '手机号应为11位', trigger: ['blur', 'change'] }
        ],
        region: [
          { required: true, message: '请选择所在区域', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: ['blur', 'change'] },
          { min: 1, max: 100, message: '详细地址长度为1-100', trigger: ['blur', 'change'] }
        ],
        isDefault: [
          { required: true, message: '请选择是否是默认地址', trigger: ['blur', 'change'] }
        ]
      }
    }
  },

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true },
    isEdit: { type: Boolean, default: false }
  },

  methods: {
    // 确认
    handleOk () {
      this.$emit('ok')
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    },

    // 修改区域信息
    handleChangeRegion (value) {
      this.form.region = value
    }
  }
}
</script>

<style scoped>

</style>
